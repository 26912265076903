import { styled, useTheme } from "@mui/material/styles"
import { Button, Typography } from "@mui/material"
import React from "react"
import { Link } from "react-router-dom"
import { APP_STORE_LINK } from "../constants"
import ReactGA from "react-ga4"

const logo = require("../images/Likely-logo.png")

const Container = styled("header")(({ color }) => ({
  backgroundColor: color,
  position: "fixed",
  paddingLeft: "15%",
  paddingRight: "15%",
  "@media (max-width: 1500px)": {
    paddingLeft: "5%",
    paddingRight: "5%",
  },
  width: "100%",
  minHeight: "70px",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}))

const Logo = styled("img")`
  max-width: 40px;
  margin-right: 10px;
`
interface HeaderProps {
  title: string
  titleFontSize: string
}


const Header: React.FC<HeaderProps> = ({ title, titleFontSize }) => {
  const theme = useTheme()
  return (
    // this needs to be the same color as "theme-color"
    <Container color={theme.palette.background.paper}>
      <Link
        to={"/"}
        style={{
          display: "flex",
          flexDirection: "row",
          textDecoration: "none",
          alignItems: "end",
          justifyContent: "center",
        }}
      >
        <Logo src={logo} alt={`Likely logo`} />
        <Typography
          align="left"
          style={{
            fontSize: titleFontSize,
            fontWeight: "bold",
            color: "black",
            marginBottom: "-4px",
          }}
        >
          {title}
        </Typography>
      </Link>
      <Button
        variant="contained"
        color="secondary"
        href={APP_STORE_LINK}
        onClick={() => {
          ReactGA.event({
            category: "User",
            action: "Clicked download button",
            label: "Header download",
          })
        }}
      >
        <Typography
          align="center"
          style={{
            fontWeight: "bold",
            color: "white",
          }}
        >
          Download app
        </Typography>
      </Button>
    </Container>
  )
}

export default Header
